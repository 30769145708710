window.angular.module('MyHippoProducer.Components').directive('sideNavSection', function () {
    return {
        restrict: 'E',
        scope: {
            label: '@',
            active: '<',
            hasError: '<',
            hasSubsections: '<',
        },
        transclude: true,
        templateUrl: 'components/side-nav/section/section.html',
    };
});
